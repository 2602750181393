import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import toast from 'react-hot-toast';

import LeftOffCanvas from '../layouts/LeftOffCanvas';
import { formatDate } from '../common';
import { environment, assets } from '../data';

export default function PayForm({
  isOpen = false,
  customFields = [],
  eventDetails,
  event_id,
  tickets = [],
  coupenCode = '',
  total = 0,
  close,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState();
  const [jsonData, setJsonData] = useState('');
  const [formData, setFormData] = useState({});
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);

  const handleTermsAndConditionsCheckboxChange = () => {
    setIsTermsAndConditionsChecked(!isTermsAndConditionsChecked);
  };

  const handleCustomInputChange = (fieldName, event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  // const handleCustomInputChange = (field_name, event) => {
  //   // const values = [...jsonData];
  //   // values[index] = event.target.value;
  //   // setJsonData(values);
  //   let data = `${field_name} : ${event.target.value},`;
  //   setJsonData(jsonData + data);
  //   console.log(jsonData);
  // };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      gender: 'M',
      // dob: '',
      verificationMethod: 'DEFAULT',
      verificationId: '',
      address: '',
    },
    mode: 'onChange',
  });

  function onSubmit(data) {
    if (!isTermsAndConditionsChecked) {
      toast.error('You have to confirm terms and conditions of attendance');
      return;
    }
    // console.log(data);
    // console.log(tickets);
    console.log(errors);

    if (isLoading || errors === null) {
      return;
    }

    let ts = [];

    tickets.forEach((t) => {
      let t1 = {
        ticket_id: t.id,
        count: t.count,
      };
      ts.push(t1);
    });

    // let emptyJson = {};
    // customFields.forEach((cf) => {
    //   emptyJson[cf.id] = getValues(`FieldNo${cf.id}`);
    //   return null;
    // });
    // console.log(emptyJson);

    let body = {
      event_id: event_id,
      customer_first_name: data.fname,
      customer_last_name: data.lname,
      customer_email: data.email,
      customer_phone_no: data.phone,
      customer_address: data.address,
      gender: data.gender,
      verification_method: data.verificationMethod,
      verification_id: data.verificationId,
      tickets: ts,
      coupon_code: coupenCode,
      //additional_fields: jsonData,
      additional_fields: JSON.stringify(formData),
    };

    console.log(body);

    setIsLoading(true);

    axios
      .post(
        `${environment.BASE_URL2}/oneticket/user/event/transaction-session/create/`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data);

        if (data?.status === 100) {
          toast.success(
            'Success! You will be redirected to onepay payment gateway',
            { id: 'buyTickets' },
          );

          if (data.data.is_pending_payment) {
            setTimeout(() => {
              setIsLoading(false);
              window.location.replace(data.data.payment_url);
            }, 4000);
          } else {
            setIsLoading(false);
            setJsonData('');
          }
        } else if (data?.status === 101) {
          toast(data?.message, {
            style: { backgroundColor: '#fff', color: '#f38f03' }, // custom info styling
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.error(data.message || 'Payment failed!');
          toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
      })
      .finally(() => {
        setJsonData('');
      });
  }

  function getTicketCount() {
    return tickets.reduce((t, num) => t + num.count, 0);
  }

  function checkVMethod() {
    const vMethod = getValues('verificationMethod');

    if (vMethod === 'DEFAULT') {
      return 'Please select a verification method';
    } else {
      return true;
    }
  }

  // check if there are emojis in the address
  function checkAddress() {
    const address = getValues('address');
    const pattern = /\p{Extended_Pictographic}/u;

    if (pattern.test(address)) {
      return "Can't use emojis";
    } else {
      return true;
    }
  }

  // function getCustomFieldsValues() {
  //   let emptyJson = {};
  //   customFields.map((data) => {
  //     emptyJson[data.id] = getValues(`FieldNo${data.id}`);
  //     return null;
  //   });
  //   console.log(emptyJson);
  //   setJsonData(emptyJson);
  //   console.log(jsonData);
  // }

  // console.log(errors);

  return (
    <LeftOffCanvas isOpen={isOpen} heading="Fill your details" close={close}>
      <div className="pay-form">
        <div className="total mb-5">
          <h5>{eventDetails?.event_name}</h5>
          <div className="d-flex align-items-center justify-content-start">
            <span className="tickets-count">
              {eventDetails.event_details === 'QX5C118E404233F1032E0'
                ? '2024/05/31 (Friday), 8:30 AM – 6:00 PM'
                : eventDetails.event_details === 'UIPA118E4147B2399589F'
                ? '2024/04/26 (Friday) 6:30 PM'
                : eventDetails.event_details === 'FCX5118E419E1E02A3EEB'
                ? '2024/05/17 (Friday) 7:00 PM'
                : formatDate(eventDetails?.event_datetime)}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-2">
            <span className="tickets-count">
              {getTicketCount() || 0}{' '}
              Tickets&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            </span>
            <span className="amount">
              Total : {total.toFixed(2)} {eventDetails?.tickets_currency}
            </span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                <label>
                  First Name <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${errors.fname ? 'on-error' : ''}`}
                  placeholder="Write your first name"
                  {...register('fname', {
                    required: {
                      value: true,
                      message: 'Please write your first name',
                    },
                  })}
                />

                {errors.fname ? (
                  <p className="error-text">{errors.fname.message}</p>
                ) : null}
              </div>

              <div className="col-12 col-sm-6 ps-0 ps-sm-2 mb-3">
                <label>
                  Last Name <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${errors.lname ? 'on-error' : ''}`}
                  placeholder="Write your last name"
                  {...register('lname', {
                    required: {
                      value: true,
                      message: 'Please write your last name',
                    },
                  })}
                />

                {errors.lname ? (
                  <p className="error-text">{errors.lname.message}</p>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                <label>
                  Email address <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${errors.email ? 'on-error' : ''}`}
                  placeholder="Write your email address"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Please write your email',
                    },
                  })}
                />

                {errors.email ? (
                  <p className="error-text">{errors.email.message}</p>
                ) : null}
              </div>

              <div className="col-12 col-sm-6 ps-0 ps-sm-2 mb-3">
                <label>
                  Phone number <span className="required-sign">*</span>
                </label>

                <input
                  type="tel"
                  className={`form-control ${errors.phone ? 'on-error' : ''}`}
                  placeholder="Write your phone number"
                  {...register('phone', {
                    required: {
                      value: true,
                      message: 'Please write your phone number',
                    },
                    pattern: {
                      value: /^\+?[0-9]{8}[0-9]*$/,
                      message: 'Invalid phone number',
                    },
                  })}
                />

                {errors.phone ? (
                  <p className="error-text">{errors.phone.message}</p>
                ) : null}
              </div>
            </div>
            {/* <div className="col-12 mb-3">
              <label>
                Email address <span className="required-sign">*</span>
              </label>

              <input
                type="email"
                className={`form-control ${errors.email ? 'on-error' : ''}`}
                placeholder="Write your email address"
                {...register('email', {
                  required: { value: true, message: 'Please write your email' },
                  pattern: {
                    value:
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                    message: 'Invalid email address',
                  },
                })}
              />

              {errors.email ? <p className="error-text">{errors.email.message}</p> : null}
            </div> */}

            {/* <div className="col-12 mb-3">
              <label>
                Phone number <span className="required-sign">*</span>
              </label>

              <input
                type="tel"
                className={`form-control ${errors.phone ? 'on-error' : ''}`}
                placeholder="Write your phone number"
                {...register('phone', {
                  required: { value: true, message: 'Please write your phone number' },
                  pattern: { value: /^\+?[0-9]{8}[0-9]*$/, message: 'Invalid phone number' },
                })}
              />

              {errors.phone ? <p className="error-text">{errors.phone.message}</p> : null}
            </div> */}

            {/* <div className="d-flex flex-column flex-sm-row align-items-start">
             

              <div className="col-12 col-sm-6 mb-3">
                <label>
                  Gender <span className="required-sign">*</span>
                </label>

                <div className="d-flex align-items-center">
                  <div className="col-4 col-sm-6 col-md-4 pe-2">
                    <input
                      type="radio"
                      className={`form-check-input me-3 ${errors.gender ? 'on-error' : ''}`}
                      id="male"
                      {...register('gender', { required: { value: true, message: 'Please select your gender' } })}
                      value="M"
                    />
                    <label htmlFor="male">Male</label>
                  </div>

                  <div className="col-4 col-sm-6 col-md-4 ps-2">
                    <input
                      type="radio"
                      className={`form-check-input me-3 ${errors.gender ? 'on-error' : ''}`}
                      id="female"
                      {...register('gender')}
                      value="F"
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                </div>

                {errors.gender ? <p className="error-text">{errors.gender.message}</p> : null}
              </div>
            </div> */}
            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                {/* <label>
                  DOB <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${errors.dob ? 'on-error' : ''}`}
                  placeholder="DD:MM:YYYY"
                  {...register('dob', {
                    required: { value: true, message: 'Please write your date of birth' },
                  })}
                />

                {errors.dob ? <p className="error-text">{errors.dob.message}</p> : null} */}
                <label>
                  Verification Method <span className="required-sign">*</span>
                </label>

                <select
                  className={`form-select ${
                    errors.verificationMethod ? 'on-error' : ''
                  }`}
                  aria-label="Default select example"
                  {...register('verificationMethod', {
                    required: {
                      value: true,
                      message: 'Please select a verification method',
                    },
                    validate: {
                      isDefault: checkVMethod,
                    },
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Select a verification method
                  </option>
                  <option value="NIC">NIC</option>
                  <option value="PASSPORT">Passport</option>
                  <option value="DRIVING_LICENCE">Driving Licence</option>
                </select>

                {errors.verificationMethod ? (
                  <p className="error-text">
                    {errors.verificationMethod.message}
                  </p>
                ) : null}
              </div>

              <div className="col-12 col-sm-6 ps-0 ps-sm-2 mb-3">
                <label>
                  NIC/Passport <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${
                    errors.verificationId ? 'on-error' : ''
                  }`}
                  placeholder="NIC / Passport No"
                  {...register('verificationId', {
                    required: {
                      value: true,
                      message: 'Please select your verification id',
                    },
                  })}
                />

                {errors.verificationId ? (
                  <p className="error-text">{errors.verificationId.message}</p>
                ) : null}
              </div>
            </div>

            {/* <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                <label>
                  DOB <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${errors.dob ? 'on-error' : ''}`}
                  placeholder="DD:MM:YYYY"
                  {...register('dob', {
                    required: {
                      value: false,
                      message: 'Please write your date of birth',
                    },
                  })}
                />

                {errors.dob ? (
                  <p className="error-text">{errors.dob.message}</p>
                ) : null}
              </div>
            </div> */}

            <div className="col-12 mb-3">
              <label>Address</label>

              <textarea
                className={`form-control ${errors.address ? 'on-error' : ''}`}
                placeholder="Write your address"
                {...register('address', {
                  //required: { value: true, message: 'Please write your address' },
                  validate: { imoji: checkAddress },
                })}
                rows={5}
              />

              {errors.address ? (
                <p className="error-text">{errors.address.message}</p>
              ) : null}
            </div>
            {customFields.map((field) => (
              <div key={field.id} className="col-12 mb-3">
                <label>{field.field_name}</label>

                <input
                  key={field.id}
                  type="text"
                  className={`form-control ${errors.phone ? 'on-error' : ''}`}
                  placeholder="Write here"
                  {...register(`FieldNo${field.id}`, {
                    required: { value: false, message: '' },
                    pattern: { value: field.field_regex, message: '' },
                  })}
                  onChange={(event) =>
                    handleCustomInputChange(field.field_name, event)
                  }
                />

                {errors.phone ? (
                  <p className="error-text">{errors.phone.message}</p>
                ) : null}
              </div>
            ))}
            {/* <div className="logos col-12 col-sm-8 mx-auto">
              <div className="col-5">
                <img src={assets.onepay_pg_logo} alt="payment options" loading="lazy" />
              </div>

              <div className="col-7">
                <img src={assets.logos_img} alt="payment options" loading="lazy" />
              </div>
            </div> */}
            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="col-12 mb-3 d-flex flex-column flex-sm-row align-items-start">
                {/* <label>
                  Gender <span className="required-sign">*</span>
                  </label> */}
                <input
                  type="checkbox"
                  className={`form-check-input me-3`}
                  style={{ cursor: 'pointer' }}
                  id="acceptConditions"
                  onChange={handleTermsAndConditionsCheckboxChange}
                  checked={isTermsAndConditionsChecked}
                />
                <label htmlFor="acceptConditions">
                  I have read the Terms and Conditions of attendance.
                </label>

                {/* {errors.acceptConditions ? <p className="error-text">{errors.acceptConditions.message}</p> : null} */}
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-end">
              <div className="col-12 col-sm-3 pe-0">
                <img
                  src={assets.onepay_pg_logo}
                  alt="payment options"
                  loading="lazy"
                />
              </div>

              <div className="col-12 col-sm-3 pe-0">
                <img
                  src={assets.logos_img}
                  alt="payment options"
                  loading="lazy"
                />
              </div>
              <div className="col-12 col-sm-6 pe-0 d-flex align-items-center justify-content-end mt-2">
                <button
                  className="pay-btn btn primary-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    'Proceed to Pay'
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </LeftOffCanvas>
  );
}
