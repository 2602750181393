//import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatUrl, formatDate } from '../common';
import { assets, environment } from '../data';

export default function Event({ event, disable }) {
  const navigate = useNavigate();

  //useEffect(() => {});

  return (
    <div className="event">
      <div className="banner-wrapper mb-1">
        {/* <div
          className="banner"
          style={{
            backgroundImage: `url(${
              event?.event_banner
                ? environment.gpcStorage + event.event_banner
                : assets.sample_img
            })`,
          }}
        ></div> */}
        <img
          className="banner"
          src={
            event?.event_banner
              ? environment.gpcStorage + event.event_banner
              : assets.sample_img
          }
          alt="event cover"
          loading="lazy"
        />
      </div>

      <h3>{event?.event_name || 'Not Available'}</h3>

      <p className="date mb-3">
        {event.event_details === 'FRQM118C368CAF159CBEE'
          ? '2024/02/10 (Saturday) 6:00 PM'
          : event.event_details === 'DH36118E3A74B95C14157'
          ? '2024/03/16 (Saturday) 7:00 PM'
          : event.event_details === 'QX5C118E404233F1032E0'
          ? '2024/05/31 (Friday), 8:30 AM – 6:00 PM'
          : event.event_details === 'UIPA118E4147B2399589F'
          ? '2024/04/26 (Friday) 6:30 PM'
          : event.event_details === 'FCX5118E419E1E02A3EEB'
          ? '2024/05/17 (Friday) 7:00 PM'
          : formatDate(event?.event_datetime)}
      </p>

      <button
        className={`${disable ? 'buy-btn-disable' : 'primary-btn buy-btn'}`}
        disabled={disable}
        onClick={() => navigate(`/${event.event_details}`)}
      >
        Buy Tickets
      </button>
    </div>
  );
}
